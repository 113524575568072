import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import React from "react";
import Modal from "react-modal"; // Importiere react-modal
import KvkkPolitikasi from "./kvkkpolitikasi";
import MusteriAydinlatmaMetni from "./mam"; // Importiere das Aydınlatma Metni

const initialState = {
  name: "",
  surname: "",
  phone: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, surname, phone, email, message }, setState] = useState(initialState);
  const [isSent, setIsSent] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // Zustand für das KVKK Modal
  const [musteriModalIsOpen, setMusteriModalIsOpen] = useState(false); // Zustand für das Müşteri Aydınlatma Metni Modal

  // Modal-Styles für react-modal
  const customStyles = {
    content: {
      top: '55%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '90%', // Setze die Breite auf 90%
      maxWidth: '500px', // Maximale Breite
      maxHeight: '80vh', // Maximale Höhe des Modals
      overflowY: 'auto', // Ermögliche vertikales Scrollen
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => {
    setState({ ...initialState });
    setIsSent(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_m5768dj",
        "template_5f8cyed",
        e.target,
        "kHsSZIhvlidAB-1Fj"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // Modal für KVKK öffnen
  const openModal = () => setModalIsOpen(true);
  // Modal für KVKK schließen
  const closeModal = () => setModalIsOpen(false);
  
  // Modal für Müşteri Aydınlatma Metni öffnen
  const openMusteriModal = () => setMusteriModalIsOpen(true);
  // Modal für Müşteri Aydınlatma Metni schließen
  const closeMusteriModal = () => setMusteriModalIsOpen(false);

  // Modal nach dem Rendern für barrierefreien Zugriff binden
  useEffect(() => {
    Modal.setAppElement('body'); // Setze das App-Element für Screenreader
  }, []);

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>İLETİŞİM FORMU</h2>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Ad"
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="surname"
                        name="surname"
                        className="form-control"
                        placeholder="Soyad"
                        required
                        onChange={handleChange}
                        value={surname}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Telefon"
                        required
                        onChange={handleChange}
                        value={phone}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mesajınız..."
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Gönder
                </button>
              </form>
              {isSent && (
                <p
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    marginTop: "10px",
                  }}
                >
                  Mesajınız başarıyla gönderildi. En kısa sürede sizinle iletişime geçeceğiz.
                </p>
              )}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>İLETİŞİM BİLGİLERİ</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Adres
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefon
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href="https://www.instagram.com/groupmkl">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.whatsapp : "/"}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
                <div id="footerNav">
                  <ul>
                    <li>
                      <a href="#services" className="page-scroll">HİZMETLERİMİZ</a>
                    </li>
                    <li>
                      <a href="#about" className="page-scroll">KURUMSAL</a>
                    </li>
                    <li>
                      <a href="#portfolio" className="page-scroll">PROJELERİMİZ</a>
                    </li>
                  </ul>
                </div>

                <div id="footerNavImportant">
                  <ul>
                    <li>
                      <a className="page-scroll" onClick={openModal}>
                        KVKK Politikası
                      </a>
                    </li>
                    <li>
                      <a className="page-scroll" onClick={openMusteriModal}>
                        Müşteri Aydınlatma Metni
                      </a>
                    </li>
                  </ul>
                  <div className="container text-center">
                    <p>&copy; 2024 MKL GROUP</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* React Modal für KVKK Politikası */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="KVKK Politikası"
      >
        <h2>KVKK Politikası</h2>
        <KvkkPolitikasi />
        <button onClick={closeModal} style={{ marginTop: '20px' }}>
          Kapat
        </button>
      </Modal>

      {/* React Modal für Müşteri Aydınlatma Metni */}
      <Modal
        isOpen={musteriModalIsOpen}
        onRequestClose={closeMusteriModal}
        style={customStyles}
        contentLabel="Müşteri Aydınlatma Metni"
      >
        <h2>Müşteri Aydınlatma Metni</h2>
        <MusteriAydinlatmaMetni />
        <button onClick={closeMusteriModal} style={{ marginTop: '20px' }}>
          Kapat
        </button>
      </Modal>
    </div>
  );
};
