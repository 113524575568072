import React from 'react';

const KvkkPolitikasi = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Kişisel Verilerin Korunması ve Gizlilik Politikası</h1>
      
      <h2>1. Giriş</h2>
      <p>
        MKL GROUP TİCARET olarak, kişisel verilerinizin gizliliğine ve korunmasına büyük önem veriyoruz. 
        Bu politika, kişisel verilerinizin nasıl toplandığını, kullanıldığını, saklandığını ve korunduğunu açıklamaktadır.
      </p>
      
      <h2>2. Kişisel Verilerin Toplanma Yöntemleri</h2>
      <p>
        Kişisel verileriniz, web sitemiz üzerinden sağladığınız bilgiler (örneğin, iletişim formları) ile toplanmaktadır. 
        Ayrıca, web sitemizi ziyaretiniz sırasında otomatik olarak toplanan bilgiler (örn. IP adresi, tarayıcı bilgileri) de kişisel veri olarak değerlendirilmektedir.
      </p>
      
      <h2>3. Toplanan Kişisel Veriler</h2>
      <ul>
        <li>İsim ve Soyad</li>
        <li>E-posta Adresi</li>
        <li>Telefon Numarası</li>
        <li>IP Adresi</li>
        <li>Kullanım Verileri (web sitesi etkileşimleri)</li>
      </ul>
      
      <h2>4. Kişisel Verilerin Kullanım Amaçları</h2>
      <p>
        Topladığımız kişisel verileri şu amaçlarla kullanabiliriz:
      </p>
      <ul>
        <li>Hizmetlerimizi sunmak ve geliştirmek</li>
        <li>Müşteri destek hizmetleri sağlamak</li>
        <li>İletişim kurmak ve bilgilendirme yapmak</li>
        <li>EmailJS kullanarak iletişim formu aracılığıyla kullanıcılarla iletişim kurmak</li>
        <li>Yasal yükümlülüklere uymak</li>
      </ul>
      
      <h2>5. Kişisel Verilerin Saklanması</h2>
      <p>
        Kişisel verileriniz, yalnızca yukarıda belirtilen amaçlar için gerekli olduğu süre boyunca saklanacaktır. 
        Bu süre sona erdiğinde, verileriniz güvenli bir şekilde silinecektir.
      </p>
      
      <h2>6. Kişisel Verilerin İfşa Edilmesi</h2>
      <p>
        Kişisel verilerinizi, yalnızca yasal yükümlülüklerimizi yerine getirmek amacıyla veya sizin açık rızanızla üçüncü şahıslarla paylaşabiliriz. 
        Bu paylaşımlar, veri güvenliğini sağlamak için gerekli önlemler alınarak gerçekleştirilecektir.
      </p>
      
      <h2>7. Kişisel Verilerin Güvenliği</h2>
      <p>
        Kişisel verilerinizi korumak için gerekli teknik ve idari önlemleri alıyoruz. 
        Ancak, internet üzerinden yapılan veri iletimlerinde her zaman bir risk bulunabileceğini unutmamanız önemlidir.
      </p>
      
      <h2>8. Kullanıcı Hakları</h2>
      <p>
        Kişisel verilerinizi koruma hakkına sahipsiniz. Bu haklar şunları içerir:
      </p>
      <ul>
        <li>Kişisel verilerinize erişim talep etme</li>
        <li>Kişisel verilerinizi düzeltme veya silme</li>
        <li>İşleme itiraz etme</li>
        <li>Verilerin taşınabilirliğini talep etme</li>
      </ul>
      
      <h2>9. İletişim Bilgileri</h2>
      <p>
        Bu politika hakkında sorularınız veya talepleriniz varsa, bizimle aşağıdaki iletişim bilgileri aracılığıyla iletişime geçebilirsiniz:
      </p>
      <p>
        E-posta: info@mklgrouptr.com <br />
        Telefon: 0 537 459 88 07
      </p>
      
      <h2>10. Politika Değişiklikleri</h2>
      <p>
        Bu politika, ihtiyaç duyuldukça güncellenebilir. Yapılan değişiklikler, web sitemizde yayınlanacak 
        ve geçerli tarih belirtilerek sizlere duyurulacaktır.
      </p>
    </div>
  );
};

export default KvkkPolitikasi;
