import React from 'react';

const MusteriAydinlatmaMetni = () => {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Müşteri Aydınlatma Metni</h1>
      
      <h2>1. Kişisel Verilerin Toplanması</h2>
      <p>
        MKL GROUP TİCARET olarak, kişisel verilerinizin korunmasına ve gizliliğine büyük önem veriyoruz. 
        Kişisel verileriniz, web sitemizi ziyaret ettiğinizde veya hizmetlerimizi kullandığınızda, 
        sizden sağlanan bilgilerle veya otomatik yöntemlerle toplanabilir. Toplayabileceğimiz kişisel veriler şunları içerebilir:
      </p>
      <ul>
        <li>İsim</li>
        <li>Soyisim</li>
        <li>E-posta adresi</li>
        <li>Telefon numarası</li>
        <li>Adres</li>
        <li>IP adresi</li>
        <li>Kullanıcı davranışları</li>
      </ul>
      
      <h2>2. Kişisel Verilerin Kullanımı</h2>
      <p>
        Topladığımız kişisel verileri şu amaçlarla kullanabiliriz:
      </p>
      <ul>
        <li>Hizmetlerimizi sunmak ve geliştirmek</li>
        <li>Müşteri destek hizmetleri sağlamak</li>
        <li>İletişim kurmak ve bilgilendirme yapmak</li>
        <li>Yasal yükümlülüklere uymak</li>
      </ul>
      
      <h2>3. Kişisel Verilerin Saklanması</h2>
      <p>
        Kişisel verileriniz, yalnızca belirlenen amaçlar doğrultusunda ve yasal gerekliliklere uygun olarak saklanacaktır. 
        Verileriniz, gerektiği süre boyunca güvenli bir şekilde korunacaktır.
      </p>
      
      <h2>4. Kişisel Verilerin İfşa Edilmesi</h2>
      <p>
        Kişisel verilerinizi, yalnızca yasal yükümlülüklerimizi yerine getirmek amacıyla veya sizin açık rızanızla üçüncü şahıslarla paylaşabiliriz. 
        Bu paylaşımlar, veri güvenliğini sağlamak için gerekli önlemler alınarak gerçekleştirilecektir.
      </p>
      
      <h2>5. Kişisel Verilerin Güvenliği</h2>
      <p>
        Kişisel verilerinizi korumak için gerekli teknik ve idari önlemleri alıyoruz. 
        Ancak, internet üzerinden yapılan veri iletimlerinde her zaman bir risk bulunabileceğini unutmamanız önemlidir.
      </p>
      
      <h2>6. Kullanıcı Hakları</h2>
      <p>
        Kişisel verilerinizi koruma hakkına sahipsiniz. Bu haklar şunları içerir:
      </p>
      <ul>
        <li>Kişisel verilerinize erişim talep etme</li>
        <li>Kişisel verilerinizi düzeltme veya silme</li>
        <li>İşleme itiraz etme</li>
        <li>Verilerin taşınabilirliğini talep etme</li>
      </ul>
      
      <h2>7. İletişim Bilgileri</h2>
      <p>
        Bu aydınlatma metni hakkında sorularınız veya talepleriniz varsa, bizimle aşağıdaki iletişim bilgileri aracılığıyla iletişime geçebilirsiniz:
      </p>
      <p>
        E-posta: info@mklgrouptr.com <br />
        Telefon: 0 537 459 88 07
      </p>
    </div>
  );
};

export default MusteriAydinlatmaMetni;
